import React, {useEffect, useState} from "react"
import { Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import cresusFond from "../../assets/images/cresuFond.jpg"
import { Grid } from "react-loader-spinner"
import APIS from "../../apis"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Flip } from "react-toastify"

const Compte = props => {
  const [cin, setCin] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectAnnee, setSelectAnnee] = useState("");
  const [annee, setAnnee] = useState("");
  useEffect(async () => {
    const resAnnee = await APIS.get("annee/select").then(resAnnee =>{
      setSelectAnnee(resAnnee.data.Annee[0].CurrentAnnee[0].value)
      setAnnee(resAnnee.data.Annee[0].CurrentAnnee[0].label)
    })

  }, [])
  const save = async () => {
    setLoading(false)
    if (cin == "") {
      setLoading(true)
      toast.error("⛔ CIN obligatoire !!", {containerId: "A"})
    }else if(email == ""){
      setLoading(true)
      toast.error("⛔ EMAIL obligatoire !!", {containerId: "A" })
    }else if(password != ""){
      APIS.post("Compte/add", {
        cin,
        email,
        password,
        annee_id:selectAnnee
      }).then(res => {
        if(res.data.errNum === "500"){
          setLoading(true)
          toast.error("⛔ Vous avez dejà un compte !!", {containerId: "A"})
        }else{
          toast.success("Votre compte a été ajouter avec succes", {containerId: "A"})
          props.history.push("/login" )
        }
      })
    }else{
      setLoading(true)
      toast.error("⛔ Mot de passe obligatoire !!", {containerId: "A"})
    }
  }
  const retour = async () => {
    props.history.push("/Redirection")
  }
  return (
      <div>
        {loading ? (
            <div
                style={{
                  backgroundImage: `url(${cresusFond})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "100vh",
                  width: "100vw",
                }}
            >
              <div style={{ paddingTop: "7%" }}>
                <Container>
                  <Row className="justify-content-center">
                    <Col md={8} lg={6} xl={5}>
                      <Card className="overflow-hidden">
                        <div>
                          <Row>
                            <Col className="justify-content-center pt-4">
                              <h3
                                  style={{
                                    color: "#485ec4",
                                    textAlign: "center",
                                  }}
                              >
                                <p>Créer vos comptes</p>
                                <p>A.U {annee}</p>
                              </h3>
                            </Col>
                          </Row>
                        </div>
                        <CardBody className="pt-4">
                          <Col lg="12">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                CIN/ N° Passeport pour les étrangers :
                              </Label>
                              <Input
                                  placeholder="Entrer CIN/ N° Passeport"
                                  type="text"
                                  className="form-control "
                                  onChange={e => setCin(e.target.value)}
                                  required={true}
                              />
                            </div>
                          </Col>
                          <Col lg="12">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Email :
                              </Label>
                              <Input
                                  placeholder="Entrer Email"
                                  type="email"
                                  className="form-control "
                                  required={true}
                                  onChange={e => setEmail(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg="12">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Mot de passe :
                              </Label>
                              <Input
                                  placeholder="Entrer Mot de passe"
                                  type="password"
                                  className="form-control "
                                  required={true}
                                  onChange={e => setPassword(e.target.value)}
                              />
                            </div>
                          </Col>
                          <div className="p-2">
                            <div className="mt-3 d-grid">
                              <button
                                  onClick={save}
                                  className="btn btn-primary btn-block"
                                  type="submit"
                              >
                                Connexion
                              </button>
                            </div>
                          </div>
                          <div className="p-2">
                            <div className="mt-3 d-grid">
                              <button
                                  onClick={retour}
                                  className="btn btn-warning btn-block"
                                  type="submit"
                              >
                                Annuler
                              </button>
                            </div>
                          </div>
                        </CardBody>
                        <div className="mt-1 text-center">
                          <p>
                            © {new Date().getFullYear()} Cresus. Crafted with{" "}
                            <i className="mdi mdi-heart text-danger" /> by{" "}
                            <a href="https://cresus.pro/">cresus.pro</a>
                          </p>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Container>
                <ToastContainer
                    transition={Flip}
                    enableMultiContainer
                    containerId={"A"}
                    position={toast.POSITION.TOP_RIGHT}
                    autoClose={2500}
                />
              </div>
            </div>
        ) : (
            <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-50px",
                  marginLeft: "-50px",
                }}
            >
              <Grid heigth="100" width="100" color="#86B5E9" ariaLabel="loading" />
            </div>
        )}
      </div>
  )
}

export default withRouter(Compte)

Compte.propTypes = {
  history: PropTypes.object,
}
