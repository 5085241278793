import AvField from "availity-reactstrap-validation/lib/AvField"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import React, {useEffect, useState, useMemo} from "react"
import {
    Card,
    CardBody,
    Col,
    Container,
    Label,
    Row,
    Button,
    Input,
    CardTitle,
    Spinner,
    ModalHeader,
    ModalBody,
    Modal,
} from "reactstrap"
import Select from "react-select"
import APIS from "../../apis"
import API from "../../api"
import {withRouter} from "react-router-dom"
import PropTypes from "prop-types"
import countryList from "react-select-country-list"
// Toastify
import {ToastContainer, toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {Flip} from "react-toastify"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {setGlobalCssModule} from "reactstrap/es/utils"
import demo from "../../assets/videos/demoEtud.mp4"
import cresusFond from "../../assets/images/cresuFond.jpg";

const Profile = props => {
    var userAuthEtudiant = JSON.parse(localStorage.getItem("userAuthEtudiant"))
    var etudiant_id = userAuthEtudiant.user.id_etudiant
    var annee_id = userAuthEtudiant.user.annee_id
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [nom, setNom] = useState("");
    const [prenom, setPrenom] = useState("");
    const [lieuNaissance, setLieuNaissance] = useState("")
    const [dateNaissance, setDateNaissance] = useState("");
    const [sexe, setSexe] = useState(1);
    const [cin, setCin] = useState("");
    const [email, setEmail] = useState("");
    const [etatCivil, setEtatCivil] = useState(1);
    const [telephone, setTelephone] = useState("");
    const [selectNationnalite, setSelectNationnalite] = useState("");
    const options = useMemo(() => countryList().getData(), [])
    const [gouvernorat, setGouvernorat] = useState([]);
    const [selectGouvernorat, setSelectGouvernorat] = useState("");
    const [rue, setRue] = useState("");
    const [codePostal, setCodePostal] = useState("");
    const [photo, setPhoto] = useState("");
    const [typeInscription, setTypeInscription] = useState([]);
    const [selectTypeInscription, setSelectTypeInscription] = useState("");
    const [cycle, setCycle] = useState([])
    const [selectCycle, setSelectCycle] = useState("")
    const [niveau, setNiveau] = useState([])
    const [selectNiveau, setSelectNiveau] = useState("")
    const [specialite, setSpecialite] = useState([])
    const [selectSpecialite, setSelectSpecialite] = useState("")
    const [mention, setMention] = useState([])
    const [selectMention, setSelectMention] = useState("")
    const [session, setSession] = useState([])
    const [selectSession, setSelectSession] = useState("")
    const [section, setSection] = useState([])
    const [selectSection, setSelectSection] = useState("")
    const [anneeBac, setAnneeBac] = useState("");
    const [groupe, setGroupe] = useState([]);
    const [selectGroupe, setSelectGroupe] = useState("");
    const [password, setPassword] = useState("")
    const [verifPassword, setVerifPassword] = useState("")
    const [errPass, setErrPass] = useState(1)
    const [file, setFile] = useState("");
    useEffect(async () => {
        const resC = await APIS.get("cycle/select").then(resC => {
            setCycle(resC.data.Cycle)
        })
        const res = await APIS.get("gouvernorat/select").then(res => {
            setGouvernorat(res.data.Gouvernorat)
        })
        const restype = await APIS.get("type/inscription/select").then(restype => {
            setTypeInscription(restype.data.TypeInscription)
        })
        //
        const resM = await APIS.get("mention/select").then(resM => {
            setMention(resM.data.Mention)
        })
        const resS = await APIS.get("section/select").then(resS => {
            setSection(resS.data.Section)
        })
        const resSE = await APIS.get("session/select").then(resSE => {
            setSession(resSE.data.Session)
        })
        APIS.post("etudiant/getById", {
            id: etudiant_id,
            annee_id: annee_id
        }).then(res => {
            setModal(true)
            setNom(res.data.Etudiant.nom)
            setPrenom(res.data.Etudiant.prenom)
            setLieuNaissance(res.data.Etudiant.lieuNaissance)
            setDateNaissance(new Date(res.data.Etudiant.dateNaissance))
            setSexe(res.data.Etudiant.sexe)
            setCin(res.data.Etudiant.cin)
            setEmail(res.data.Etudiant.email)
            setEtatCivil(res.data.Etudiant.etatCivil)
            setTelephone(res.data.Etudiant.telephone)
            setSelectNationnalite(res.data.Etudiant.nationality)
            setSelectGouvernorat(res.data.Etudiant.gouvernorat_id)
            setRue(res.data.Etudiant.rue)
            setCodePostal(res.data.Etudiant.codePostal)
            setLoading(true)
            setPhoto(res.data.Etudiant.fichier)
            setSelectTypeInscription(res.data.Etudiant.type_inscription_id)
            setSelectSection(res.data.Etudiant.section_id)
            setSelectSession(res.data.Etudiant.session_id)
            setSelectMention(res.data.Etudiant.mention_id)
            setSelectCycle(res.data.Etudiant.cycle_id)
            setAnneeBac(res.data.Etudiant.anneBac)
            setSelectNiveau(res.data.Etudiant.niveau_id)
            setSelectSpecialite(res.data.Etudiant.specialite_id)
            setSelectGroupe(res.data.Etudiant.group_td_id)
        })
    }, [])
    const addFile = e => {
        var file = e.target.files[0]
        setFile(file)
    }
    const passVerif = event => {
        var password = event

            APIS.post("UserEtudiant/password_verification", {
                etudiant_id: etudiant_id,
                password: password,
                annee_id
            }).then(res => {
                var etat = res.data.Etudiant.etat
                if (etat == 0) {
                    setErrPass(0)
                } else {
                    setErrPass(1)
                }
            })


    }
    const toggle = () => {
        setModal(false)
    }
    const edit = async () => {
        if (dateNaissance != "") {
            let dateNaiss = dateNaissance
            let month = "" + (dateNaiss.getMonth() + 1)
            let day = "" + dateNaiss.getDate()
            let year = dateNaiss.getFullYear()
            if (month.length < 2) month = "0" + month
            if (day.length < 2) day = "0" + day
            var convertDate = [year, month, day].join("-")
        } else {
            var convertDate = ""
        }
        //Test si file vide
        var pathFile = ""
        if (file != "") {
            pathFile = file.name
        }
        var image =""
        if(photo == null){
            image=pathFile
        }else{
            image=photo
        }
        if (password == "") {
            var pass = 0
        } else {
            var pass = password
        }
        var nationalite_id = selectNationnalite.label || 0
        if (nom == "") {
            toast.error("⛔ Nom  obligatoire !! ", {
                containerId: "A",
            })
        } else if (prenom == "") {
            toast.error("⛔ Prénom obligatoire !! ", {
                containerId: "A",
            })
        }else if(dateNaissance == ""){
            toast.error("⛔ Date de naissance obligatoire !! ", {
                containerId: "A",
            })
        }else if (lieuNaissance ==""){
            toast.error("⛔ Lieu de naissance obligatoire !! ", {
                containerId: "A",
            })
        }else if(cin ==""){
            toast.error("⛔ CIN obligatoire !! ", {
                containerId: "A",
            })
        }else if (email =="") {
            toast.error("⛔ Email obligatoire !!", {
                containerId: "A",
            })
        }else if (telephone == ""){
            toast.error("⛔ Téléphone obligatoire !! ", {
                containerId: "A",
            })
        }else if(selectGouvernorat==""){
            toast.error("⛔ Gouvernorat obligatoire !! ", {
                containerId: "A",
            })
        }else if(rue == ""){
            toast.error("⛔ Rue obligatoire !! ", {
                containerId: "A",
            })
        }else if(codePostal == ""){
            toast.error("⛔ Code postal obligatoire !! ", {
                containerId: "A",
            })
        }else if(selectTypeInscription == ""){
            toast.error("⛔ Type d'inscription obligatoire !! ", {
                containerId: "A",
            })
        }else if(anneeBac== ""){
            toast.error("⛔ Année du bac obligatoire !! ", {
                containerId: "A",
            })
        }else if(selectSection == ""){
            toast.error("⛔ Section obligatoire !! ", {
                containerId: "A",
            })
        }else if(selectSession == ""){
            toast.error("⛔ Session obligatoire !! ", {
                containerId: "A",
            })
        }else if(selectMention != ""){

            if (password == verifPassword) {

                const res = await APIS.post("etudiant/edit", {
                    id: etudiant_id,
                    nom: nom,
                    prenom: prenom,
                    dateNaissance: convertDate,
                    lieuNaissance:lieuNaissance,
                    cin:cin,
                    email:email,
                    sexe: sexe,
                    etatCivil:etatCivil,
                    telephone:telephone,
                    nationalite: nationalite_id,
                    gouvernorat:selectGouvernorat.value,
                    fichier: image,
                    rue: rue,
                    codePostal: codePostal,
                    type_inscription_id:selectTypeInscription.value,
                    anneeBac:anneeBac,
                    section_id:selectSection.value,
                    session_id:selectSession.value,
                    mention_id:selectMention.value,
                    password: pass,
                    annee_id
                }).then(res => {
                    if (res.data.errNum == 200) {
                        if(photo == null){
                            if (pathFile ) {
                                const formData = new FormData()
                                formData.append("document", file)
                                formData.append("id", etudiant_id)
                                formData.append("annee_id", annee_id)
                                const resA = APIS.post("etudiant/import_photo", formData).then(
                                    resA => {
                                        props.history.push("/dashboard")
                                    }
                                )
                            } else {
                                props.history.push("/dashboard")
                            }
                        }else {
                            props.history.push("/dashboard")
                        }

                    } else if (res.data.errNum === 300) {
                        toast.error("⛔ CIN déja existe !!", {
                            containerId: "A",
                        })
                    } else if (res.data.errNum === 301) {
                        toast.error("⛔ E-mail déja existe !!", {
                            containerId: "A",
                        })
                    } else if (res.data.errNum === 302) {
                        toast.error("⛔ CIN invalide !!", {
                            containerId: "A",
                        })
                    } else if (res.data.errNum === 500) {
                        toast.error("⛔ E-mail invalide !!", {
                            containerId: "A",
                        })
                    }
                })
            }

        }else{
            toast.error("⛔ Mention obligatoire !! ", {
                containerId: "A",
            })
        }
    }
    return (
        <div

        >
            <React.Fragment>
                <div className="page-content">
                    {loading ? (
                        <Container fluid>
                            <Row className="justify-content-center">
                                <Col md={12} lg={12} xl={12}>
                                    <Card>
                                        <div>
                                            <CardBody className="pt-4">
                                                <CardTitle
                                                    style={{color: "red"}}
                                                    className="h4 mb-2 mt-4"
                                                >
                                                    {
                                                        "NB : veuillez d'abord remplir les détails de votre profil"
                                                    }
                                                </CardTitle>
                                                <CardTitle
                                                    style={{color: "#556ee6"}}
                                                    className="h4 mb-4 mt-4"
                                                >
                                                    Informations générales :
                                                </CardTitle>
                                                <AvForm className="form-horizontal">
                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label for="basicpill-firstname-input1"> Nom <span
                                                                    style={{
                                                                        color: "red",
                                                                        paddingLeft: "5px"
                                                                    }}> * </span> :
                                                                </Label>
                                                                <AvField
                                                                    name="prenom"
                                                                    placeholder="Entrer Prénom"
                                                                    type="text"
                                                                    errorMessage="* Nom obligatoire"
                                                                    className="form-control "
                                                                    validate={{required: {value: true}}}
                                                                    value={nom}
                                                                    onChange={e => setNom(e.target.value)}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label for="basicpill-firstname-input1"> Prénom <span
                                                                    style={{
                                                                        color: "red",
                                                                        paddingLeft: "5px"
                                                                    }}> * </span> :
                                                                </Label>
                                                                <AvField
                                                                    name="prenom"
                                                                    placeholder="Entrer Prénom"
                                                                    type="text"
                                                                    errorMessage="* Prénom obligatoire"
                                                                    className="form-control "
                                                                    validate={{required: {value: true}}}
                                                                    onChange={e => setPrenom(e.target.value)}
                                                                    value={prenom}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label for="basicpill-firstname-input1"> Date de
                                                                    Naissance <span
                                                                        style={{
                                                                            color: "red",
                                                                            paddingLeft: "5px"
                                                                        }}> * </span> :
                                                                </Label>
                                                                <DatePicker
                                                                    name="dateNaissance"
                                                                    className="form-control ddate"
                                                                    selected={dateNaissance}
                                                                    onChange={setDateNaissance}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="dd/MM/yyyy"
                                                                    validate={{required: {value: true}}}
                                                                    errorMessage="* Date de naissance obligatoire"
                                                                    value={dateNaissance}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label for="basicpill-firstname-input1"> Lieu de
                                                                    Naissance <span
                                                                        style={{
                                                                            color: "red",
                                                                            paddingLeft: "5px"
                                                                        }}> * </span> :
                                                                </Label>
                                                                <AvField
                                                                    name="lieuNaissance"
                                                                    placeholder="Entrer Lieu de Naissance"
                                                                    type="text"
                                                                    errorMessage="* Lieu de Naissance obligatoire"
                                                                    className="form-control "
                                                                    validate={{required: {value: true}}}
                                                                    onChange={e => setLieuNaissance(e.target.value)}
                                                                    value={lieuNaissance}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label for="basicpill-firstname-input1"> N° CIN <span
                                                                    style={{
                                                                        color: "red",
                                                                        paddingLeft: "5px"
                                                                    }}> * </span> : </Label>
                                                                <AvField
                                                                    name="cin"
                                                                    placeholder="Entrer Lieu de Naissance"
                                                                    type="text"
                                                                    errorMessage="* CIN obligatoire"
                                                                    className="form-control "
                                                                    validate={{required: {value: true}}}
                                                                    onChange={e => setCin(e.target.value)}
                                                                    value={cin}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label for="basicpill-firstname-input1"> Email <span
                                                                    style={{
                                                                        color: "red",
                                                                        paddingLeft: "5px"
                                                                    }}> * </span> : </Label>
                                                                <AvField
                                                                    name="email"
                                                                    placeholder="Entrer email"
                                                                    type="email"
                                                                    errorMessage="* email obligatoire"
                                                                    className="form-control "
                                                                    validate={{required: {value: true}}}
                                                                    onChange={e => setEmail(e.target.value)}
                                                                    value={email}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="3">
                                                            <div className="mb-3">
                                                                <Label className="d-block mb-3">Sexe <span
                                                                    style={{
                                                                        color: "red",
                                                                        paddingLeft: "5px"
                                                                    }}> * </span> :</Label>
                                                                <div className="form-check form-check-inline">
                                                                    <Input
                                                                        type="radio"
                                                                        id="customRadioInline1"
                                                                        name="customRadioInline1"
                                                                        className="form-check-input"
                                                                        checked={sexe === 1}
                                                                        onClick={() => setSexe(1)}
                                                                    />
                                                                    <Label className="form-check-label"
                                                                           htmlFor="customRadioInline1"> Masculin </Label>
                                                                </div>

                                                                <div className="form-check form-check-inline">
                                                                    <Input
                                                                        type="radio"
                                                                        id="customRadioInline2"
                                                                        name="customRadioInline1"
                                                                        className="form-check-input"
                                                                        checked={sexe === 2}
                                                                        onClick={() => setSexe(2)}
                                                                    />
                                                                    <Label className="form-check-label"
                                                                           htmlFor="customRadioInline2"> Feminin </Label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col lg="3">
                                                            <div className="mb-3">
                                                                <Label className="d-block mb-3">Etat Civil <span
                                                                    style={{
                                                                        color: "red",
                                                                        paddingLeft: "5px"
                                                                    }}> * </span> :</Label>
                                                                <div className="form-check form-check-inline">
                                                                    <label className="form-check-label left mg-r-30"
                                                                           htmlFor="customRadioInline5"> Célibataire </label>
                                                                    <Input
                                                                        type="radio"
                                                                        id="customRadioInline5"
                                                                        name="customRadioInline5"
                                                                        className="form-check-input"
                                                                        checked={etatCivil === 1}
                                                                        onChange={e => setEtatCivil(1)}
                                                                    />

                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <label className="form-check-label left mg-r-30"
                                                                           htmlFor="customRadioInline6"> Marié(e) </label>
                                                                    <Input
                                                                        type="radio"
                                                                        id="customRadioInline6"
                                                                        name="customRadioInline5"
                                                                        className="form-check-input"
                                                                        checked={etatCivil === 2}
                                                                        onChange={e => setEtatCivil(2)}
                                                                    />

                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <label className="form-check-label left mg-r-30"
                                                                           htmlFor="customRadioInline7"> Autre </label>
                                                                    <Input
                                                                        type="radio"
                                                                        id="customRadioInline7"
                                                                        name="customRadioInline5"
                                                                        className="form-check-input"
                                                                        checked={etatCivil === 3}
                                                                        onChange={e => setEtatCivil(3)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label for="basicpill-firstname-input1"> Téléphone <span
                                                                    style={{
                                                                        color: "red",
                                                                        paddingLeft: "5px"
                                                                    }}> * </span> : </Label>
                                                                <AvField
                                                                    name="telephone"
                                                                    placeholder="Entrer Téléphone"
                                                                    type="text"
                                                                    errorMessage="* Téléphone obligatoire"
                                                                    className="form-control "
                                                                    validate={{required: {value: true}}}
                                                                    onChange={e => setTelephone(e.target.value)}
                                                                    value={telephone}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label
                                                                    for="basicpill-firstname-input1"> Nationnalité <span
                                                                    style={{
                                                                        color: "red",
                                                                        paddingLeft: "5px"
                                                                    }}> * </span> : </Label>
                                                                <Select
                                                                    value={selectNationnalite}
                                                                    options={options}
                                                                    isSearchable={true}
                                                                    onChange={e => setSelectNationnalite(e)}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label
                                                                    for="basicpill-firstname-input1"> Gouvernorat <span
                                                                    style={{
                                                                        color: "red",
                                                                        paddingLeft: "5px"
                                                                    }}> * </span> : </Label>
                                                                <Select
                                                                    value={selectGouvernorat}
                                                                    options={gouvernorat}
                                                                    isSearchable={true}
                                                                    onChange={e => setSelectGouvernorat(e)}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label for="basicpill-firstname-input1"> Photo
                                                                    : </Label>
                                                                <Input
                                                                    onChange={e => addFile(e)}
                                                                    className="form-control"
                                                                    type="file"
                                                                    id="formFile"
                                                                    accept=".png, .jpeg, .jpg"
                                                                   // value={photo}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg="3">
                                                            <div className="mb-3">
                                                                <Label for="basicpill-firstname-input1"> Rue <span
                                                                    style={{
                                                                        color: "red",
                                                                        paddingLeft: "5px"
                                                                    }}> * </span> :
                                                                </Label>
                                                                <AvField
                                                                    name="rue"
                                                                    placeholder="Entrer Rue"
                                                                    type="text"
                                                                    errorMessage="* Rue obligatoire"
                                                                    className="form-control "
                                                                    validate={{required: {value: true}}}
                                                                    value={rue}
                                                                    onChange={e => setRue(e.target.value)}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg="3">
                                                            <div className="mb-3">
                                                                <Label for="basicpill-firstname-input1"> Code
                                                                    Postal <span
                                                                        style={{
                                                                            color: "red",
                                                                            paddingLeft: "5px"
                                                                        }}> * </span> :
                                                                </Label>
                                                                <AvField
                                                                    name="codePostal"
                                                                    placeholder="Entrer Code Postal"
                                                                    type="text"
                                                                    errorMessage="* Code postal obligatoire"
                                                                    className="form-control "
                                                                    validate={{required: {value: true}}}
                                                                    onChange={e => setCodePostal(e.target.value)}
                                                                    value={codePostal}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="2">
                                                            <div className="mb-3">
                                                                <Label
                                                                    for="basicpill-firstname-input1"> {"Type d'inscription :"} </Label>
                                                            </div>
                                                        </Col>
                                                        <Col lg="9" style={{marginBottom: "10px"}}>
                                                            <Select
                                                                options={typeInscription}
                                                                isSearchable={true}
                                                                value={selectTypeInscription}
                                                                onChange={e => setSelectTypeInscription(e)}
                                                            />
                                                        </Col>
                                                        <Col lg="1">
                                                            <div dir="rtl" className="mb-3 rtl">
                                                                <Label dir="ltr" for="basicpill-firstname-input1">
                                                                    : طريقة الدخول
                                                                </Label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <CardTitle style={{color: "#556ee6"}}
                                                               className="h4 mb-4 mt-4"> Baccalauréat </CardTitle>
                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label for="basicpill-firstname-input1"> Année du
                                                                    bac <span
                                                                        style={{
                                                                            color: "red",
                                                                            paddingLeft: "5px"
                                                                        }}> * </span> :
                                                                </Label>
                                                                <AvField
                                                                    name="anneeBac"
                                                                    placeholder="Entrer Année du bac"
                                                                    type="text"
                                                                    errorMessage="* Année du bac obligatoire"
                                                                    className="form-control "
                                                                    validate={{required: {value: true}}}
                                                                    value={anneeBac}
                                                                    onChange={e => setAnneeBac(e.target.value)}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label for="basicpill-firstname-input1"> Section <span
                                                                    style={{
                                                                        color: "red",
                                                                        paddingLeft: "5px"
                                                                    }}> * </span> :
                                                                </Label>
                                                                <Select
                                                                    options={section}
                                                                    isSearchable={true}
                                                                    value={selectSection}
                                                                    onChange={e => setSelectSection(e)}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label for="basicpill-firstname-input1"> Session <span
                                                                    style={{
                                                                        color: "red",
                                                                        paddingLeft: "5px"
                                                                    }}> * </span> :
                                                                </Label>
                                                                <Select
                                                                    options={session}
                                                                    isSearchable={true}
                                                                    value={selectSession}
                                                                    onChange={e => setSelectSession(e)}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label for="basicpill-firstname-input1"> Mention <span
                                                                    style={{
                                                                        color: "red",
                                                                        paddingLeft: "5px"
                                                                    }}> * </span> :
                                                                </Label>
                                                                <Select
                                                                    options={mention}
                                                                    isSearchable={true}
                                                                    value={selectMention}
                                                                    onChange={e => setSelectMention(e)}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <CardTitle style={{color: "#556ee6"}}
                                                               className="h4 mb-4 mt-4"> Dipôme </CardTitle>
                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label for="basicpill-firstname-input1"> Cycle
                                                                    : </Label>
                                                                <Select
                                                                    isDisabled={true}
                                                                    options={cycle}
                                                                    value={selectCycle}
                                                                    onChange={e => setSelectSession(e)}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label for="basicpill-firstname-input1"> Niveau
                                                                    :</Label>
                                                                <Select
                                                                    options={niveau}
                                                                    isSearchable={true}
                                                                    value={selectNiveau}
                                                                    isDisabled={true}
                                                                    onChange={e => setSelectNiveau(e)}
                                                                />
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label for="basicpill-firstname-input1"> Spécialite
                                                                    :</Label>
                                                                <Select
                                                                    options={specialite}
                                                                    isSearchable={true}
                                                                    value={selectSpecialite}
                                                                    isDisabled={true}
                                                                    onChange={e => setSelectSpecialite(e)}
                                                                />
                                                            </div>
                                                        </Col
                                                        ><Col lg="6">
                                                        <div className="mb-3">
                                                            <Label for="basicpill-firstname-input1"> Groupe :</Label>
                                                            <Select
                                                                options={groupe}
                                                                isSearchable={true}
                                                                value={selectGroupe}
                                                                isDisabled={true}
                                                                onChange={e => setSelectGroupe(e)}
                                                            />
                                                        </div>
                                                    </Col>
                                                    </Row>
                                                    <CardTitle style={{color: "#556ee6"}} className="h4 mb-4 mt-4"> Mise
                                                        a jour password : </CardTitle>
                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label>Password actuel</Label>
                                                                <Input
                                                                    lg="3"
                                                                    className="form-control"
                                                                    type="password"
                                                                    onBlur={e => passVerif(e.target.value)}
                                                                />
                                                                {errPass == 0 ? (
                                                                    <small className="form-text text-danger">
                                                                        {"Password erroné"}
                                                                    </small>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label>Nouveau password</Label>
                                                                <AvField
                                                                    name="password"
                                                                    type="password"
                                                                    value={password}
                                                                    onChange={e => setPassword(e.target.value)}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label>Verification password</Label>
                                                                <AvField
                                                                    name="password1"
                                                                    type="password"
                                                                    value={verifPassword}
                                                                    onChange={e => setVerifPassword(e.target.value)}
                                                                    errorMessage="Verifier le password"
                                                                    validate={{
                                                                        match: {value: "password"},
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        {" "}
                                                        <Col
                                                            lg="12"
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <Button
                                                                id="Confirmer"
                                                                name="Confirmer"
                                                                type="button"
                                                                color="primary"
                                                                className="btn btn-primary mb-2 me-2"
                                                                onClick={edit}
                                                            >
                                                                Confirmer
                                                            </Button>
                                                        </Col>
                                                    </div>
                                                </AvForm>
                                            </CardBody>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    ) : (
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                {" "}
                                <Spinner type="grow" className="ms-6" color="primary"/>
                            </div>
                            <h4
                                style={{textAlign: "center", marginTop: "2%"}}
                                className="ms-6"
                            >
                                {" "}
                                Chargement en cours...
                            </h4>
                        </div>
                    )}
                    <ToastContainer
                        transition={Flip}
                        enableMultiContainer
                        containerId={"A"}
                        position={toast.POSITION.TOP_RIGHT}
                        autoClose={2500}
                    />
                </div>
                <Modal
                    isOpen={modal}
                    role="dialog"
                    autoFocus={true}
                    data-toggle="modal"
                    centered
                    toggle={toggle}
                >
                    <div className="modal-content">
                        <div className="modal-header border-bottom-0">
                            <button
                                type="button"
                                className="btn-close"
                                onClick={toggle}
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="mt-4">
                                <div>
                                    <video
                                        src={demo}
                                        width="100%"
                                        controls="controls"
                                        autoPlay="true"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        </div>
    )
}

export default withRouter(Profile)

Profile.propTypes = {
    history: PropTypes.object,
}
