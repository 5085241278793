import React, {useState} from "react"
import {Button, Col, Form, Input, Label, Progress, Row} from "reactstrap"
import PropTypes from "prop-types"
import {ToastContainer, toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {Slide} from "react-toastify"
import API from "../../api"
import APIS from "../../apis"

const AddMastere = props => {
    const [disbutReg, setDisbutReg] = useState(true)
    const [file, setFile] = useState(null)
    const [file2, setFile2] = useState(null);
    const [file3, setFile3] = useState(null);
    const [fileSite, setFileSite] = useState(null);
    const [fileR1, setFileR1] = useState(null);
    const [fileR2, setFileR2] = useState(null);
    var userAuthEtudiant = JSON.parse(localStorage.getItem("userAuthEtudiant"))
    var etudiant_id = userAuthEtudiant.user.id_etudiant

    var anneeScolaire = JSON.parse(localStorage.getItem("anneeScolaire"))
    var annee = anneeScolaire.Annee.annee
    const save = async () => {
        var pathFile = ""
        var pathFile2 = ""
        var pathFile3 = ""
        var pathFile4 = ""
        var pathFile5 = ""
        var pathFile6 = ""
        if (file != null) {
            pathFile = file.name
        }else{
            setDisbutReg(true)
            toast.error("⛔  Relevé de note premiére année obligatoire", {
                containerId: "A",
            })
        }
        if (file2 != null) {
            pathFile2 = file2.name
        }else{
            setDisbutReg(true)
            toast.error("⛔ Relevé de note deuxième année obligatoire", {
                containerId: "A",
            })
        }
        if (file3 != null) {
            pathFile3 = file3.name
        }else{
            setDisbutReg(true)
            toast.error("⛔ Relevé de note troisième année obligatoire", {
                containerId: "A",
            })
        }
        if (fileSite != null) {
            pathFile4 = fileSite.name
        }else{
            setDisbutReg(true)
            toast.error("⛔ Fiche de renseignement obligatoire", {
                containerId: "A",
            })
        }
        if (pathFile != "" && pathFile2 !="" && pathFile3 !="" && pathFile4 !="") {
            setDisbutReg(false)
            let data = new FormData()
            data.append("file", file)
            data.append("id", etudiant_id)
            data.append("annee", annee)
            let data2 = new FormData()
            data2.append("file", file2)
            data2.append("id", etudiant_id)
            data2.append("annee", annee)
            let data3 = new FormData()
            data3.append("file", file3)
            data3.append("id", etudiant_id)
            data3.append("annee", annee )
            let data4 = new FormData()
            data4.append("file", fileSite)
            data4.append("id", etudiant_id)
            data4.append("annee", annee )
            let data5 = new FormData()
            data5.append("file", fileR1)
            data5.append("id", etudiant_id)
            data5.append("annee", annee )
            let data6 = new FormData()
            data6.append("file", fileR2)
            data6.append("id", etudiant_id)
            data6.append("annee", annee )
            APIS.post("etudiant/mastere/importation/1ere", data).then(res => {
                if(res.data.status == 200){
                    APIS.post("etudiant/mastere/importation/2eme", data2).then(res2 => {
                        if(res2.data.status == 200){
                            APIS.post("etudiant/mastere/importation/3eme", data3).then(res3 => {
                                if(res3.data.status == 200){
                                    APIS.post("etudiant/mastere/importation/site", data4).then(res4 => {
                                        if(res4.data.status == 200){
                                            if (fileR1 != null) {
                                                APIS.post("etudiant/mastere/importation/r1", data5).then(res5 => {
                                                    if(res5.data.status == 200){
                                                        if (fileR2 != null) {
                                                            APIS.post("etudiant/mastere/importation/r2", data6).then(res6 => {
                                                                if(res6.data.status == 200){
                                                                    props.setSection(1)
                                                                }
                                                            })
                                                        }else{
                                                            props.setSection(1)
                                                        }
                                                    }
                                                })
                                            }else{
                                                props.setSection(1)
                                            }
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
        }

    }
    const addFile = e => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile.type === "application/pdf" || selectedFile.type === "image/jpeg") {
                setFile(selectedFile);
            } else {
                toast.error("⛔ Veuillez sélectionner un fichier PDF ou une image JPEG.", {
                    containerId: "A",
                })
                e.target.value = null;
            }
        }
    }
    const addFile3 = e => {
        const selectedFile2 = e.target.files[0];
        if (selectedFile2) {
            if (selectedFile2.type === "application/pdf" || selectedFile2.type === "image/jpeg") {
                setFile2(selectedFile2);
            } else {
                toast.error("⛔ Veuillez sélectionner un fichier PDF ou une image JPEG.", {
                    containerId: "A",
                })
                e.target.value = null;
            }
        }
    }
    const addFile2 = e => {
        const selectedFile3 = e.target.files[0];
        if (selectedFile3) {
            if (selectedFile3.type === "application/pdf" || selectedFile3.type === "image/jpeg") {
                setFile3(selectedFile3);
            } else {
                toast.error("⛔ Veuillez sélectionner un fichier PDF ou une image JPEG.", {
                    containerId: "A",
                })
                e.target.value = null;
            }
        }
    }
    const addFileSite = e => {
        const selectedFile4 = e.target.files[0];
        if (selectedFile4) {
            if (selectedFile4.type === "application/pdf" || selectedFile4.type === "image/jpeg") {
                setFileSite(selectedFile4);
            } else {
                toast.error("⛔ Veuillez sélectionner un fichier PDF ou une image JPEG.", {
                    containerId: "A",
                })
                e.target.value = null;
            }
        }
    }
    const addFileR1 = e => {
        const selectedFile5 = e.target.files[0];
        if (selectedFile5) {
            if (selectedFile5.type === "application/pdf" || selectedFile5.type === "image/jpeg") {
                setFileR1(selectedFile5);
            } else {
                toast.error("⛔ Veuillez sélectionner un fichier PDF ou une image JPEG.", {
                    containerId: "A",
                })
                e.target.value = null;
            }
        }
    }
    const addFileR2 = e => {
        const selectedFile6 = e.target.files[0];
        if (selectedFile6) {
            if (selectedFile6.type === "application/pdf" || selectedFile6.type === "image/jpeg") {
                setFileR2(selectedFile6);
            } else {
                toast.error("⛔ Veuillez sélectionner un fichier PDF ou une image JPEG.", {
                    containerId: "A",
                })
                e.target.value = null;
            }
        }
    }

    return (
        <React.Fragment>
            <Row>
                <Form>
                    <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Fiche de renseignement </Label>
                                <Input
                                    lg="3"
                                    onChange={e => addFileSite(e)}
                                    className="form-control"
                                    type="file"
                                    id="formFile"
                                    accept=".pdf, .jpeg, .jpg"
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Relevé de note premiére année</Label>
                                <Input
                                    lg="3"
                                    onChange={e => addFile(e)}
                                    className="form-control"
                                    type="file"
                                    id="formFile"
                                    accept=".pdf, .jpeg, .jpg"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Relevé de note deuxième année</Label>
                                <Input
                                    lg="3"
                                    onChange={e => addFile2(e)}
                                    className="form-control"
                                    type="file"
                                    id="formFile"
                                    accept=".pdf, .jpeg, .jpg"
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Relevé de note troisième année</Label>
                                <Input
                                    lg="3"
                                    onChange={e => addFile3(e)}
                                    className="form-control"
                                    type="file"
                                    id="formFile"
                                    accept=".pdf, .jpeg, .jpg"
                                />

                            </div>
                        </Col>
                    </Row>
                    <Row>

                        <Col md={6}>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">{"Relevé de note d'année redoublement (1)"} </Label>
                                <Input
                                    lg="3"
                                    onChange={e => addFileR1(e)}
                                    className="form-control"
                                    type="file"
                                    id="formFile"
                                    accept=".pdf, .jpeg, .jpg"
                                />

                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">{"Relevé de note d'année redoublement(2)"} </Label>
                                <Input
                                    lg="3"
                                    onChange={e => addFileR2(e)}
                                    className="form-control"
                                    type="file"
                                    id="formFile"
                                    accept=".pdf, .jpeg, .jpg"
                                />

                            </div>
                        </Col>
                    </Row>
                </Form>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <Col lg="6">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                            className="text-center mt-4"
                        >
                            <Button
                                type="button"
                                color="warning"
                                className="btn btn-warning  mb-2 me-2"
                                onClick={props.back}
                            >
                                Annuler
                            </Button>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                            <Button
                                type="button"
                                color="success"
                                className="text-center mt-4"
                                onClick={save}
                                disabled={!disbutReg}
                            >
                                <i className="fas fa-cloud-upload-alt me-1"/>
                                CONFIRMER

                            </Button>
                        </div>
                    </Col>
                </div>
            </Row>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </React.Fragment>
    )
}

export default AddMastere
AddMastere.propTypes = {
    history: PropTypes.object,
    back: PropTypes.object,
    setSection: PropTypes.object,
}
