import React, {useEffect, useState} from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import cresusFond from "../../assets/images/cresuFond.jpg"
import APIS from "../../apis"
const Redirection = props => {
  const inscrit = async () => {
    props.history.push("/Register")
  }
  const exist = async () => {
    props.history.push("/CheckIns")
  }
  const compte = async () => {
    props.history.push("/Compte")
  }
  const [annee, setAnnee] = useState("")
  useEffect(async () => {
    const resAnnee = await APIS.get("annee/select").then(resAnnee =>{
      setAnnee(resAnnee.data.Annee[0].CurrentAnnee[0].label)
    })

  }, [])
  const retour = async () => {
    props.history.push("/login")
  }
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${cresusFond})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <div style={{ paddingTop: "7%" }}>
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div>
                    <Row>
                      <Col className="justify-content-center pt-4">
                        <h3
                          style={{
                            color: "#485ec4",
                            textAlign: "center",
                          }}
                        >
                          <p>FICHE DES RENSEIGNEMENTS</p>
                          <p>A.U {annee}</p>
                        </h3>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-4">
                    <div className="p-2">
                      <div className="mt-3 d-grid">
                        <button
                          onClick={inscrit}
                          className="btn btn-success btn-block"
                          type="submit"
                        >
                          {"Formulaire d'inscription"}
                        </button>
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          onClick={exist}
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Déja inscrit
                        </button>
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          onClick={compte}
                          className="btn btn-secondary btn-block"
                          type="submit"
                        >
                          Créer un compte
                        </button>
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          onClick={retour}
                          className="btn btn-warning btn-block"
                          type="submit"
                        >
                          Retour
                        </button>
                      </div>
                    </div>
                  </CardBody>
                  <div className="mt-1 text-center">
                    <p>
                      © {new Date().getFullYear()} Cresus. Crafted with{" "}
                      <i className="mdi mdi-heart text-danger" /> by{" "}
                      <a href="https://cresus.pro/">cresus.pro</a>
                    </p>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Redirection)

Redirection.propTypes = {
  history: PropTypes.object,
}
